<template>
  <div id="page-general-config">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Configuración General" icon-pack="feather" icon="icon-smartphone">
            <div class="tab-text">
              <div id="adm-config-tab">
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full">
                    <div class=" mb-6">
                        <label class="vs-input--label">Mínimo Recarga Acudiente</label>
                        <vs-input-number v-validate="'numeric|max_value:9999|max:100'" v-model="app_config.amount_min_recharge.value" :step="1" name="amount_min_recharge" data-vv-as="Mínimo Recarga Acudiente"/>
                        <span class="text-danger text-sm"  v-show="errors.has('amount_min_recharge')">{{ errors.first('amount_min_recharge') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Mínimo Transferencia a Estudiante</label>
                        <vs-input-number v-validate="'numeric|max_value:1000|min_value:1|max:100'" v-model="app_config.amount_min_trasnfer.value" :step="1" name="minimo_transferencia" data-vv-as="Mínimo Transferencia a Estudiante"/>
                        <span class="text-danger text-sm"  v-show="errors.has('minimo_transferencia')">{{ errors.first('minimo_transferencia') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Suscripción Premium Estudiante</label>
                        <vs-input-number v-validate="'max_value:5000|max:100'" v-model="app_config.price_per_student.value" :step="0.5" name="price_per_student" data-vv-as="Suscripción Premium Estudiante"/>
                        <span class="text-danger text-sm"  v-show="errors.has('price_per_student')">{{ errors.first('price_per_student') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Días de Cortesía</label>
                        <vs-input-number v-validate="'numeric|max_value:500|max:100'" v-model="app_config.courtesy_days.value" :step="1" name="courtesy_days" data-vv-as="Días de Cortesía"/>
                        <span class="text-danger text-sm"  v-show="errors.has('courtesy_days')">{{ errors.first('courtesy_days') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Comisión por Ventas Tiptap</label>
                        <vs-input-number v-validate="'max_value:100|max:3'" v-model="app_config.sales_commission.value" :step="0.5" name="sales_commission" data-vv-as="Comisión por Ventas Tiptap"/>
                        <span class="text-danger text-sm"  v-show="errors.has('sales_commission')">{{ errors.first('sales_commission') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Comisión por Anualidad</label>
                        <vs-input-number v-validate="'max_value:100|max:3'" v-model="app_config.annual_commission.value" :step="0.5" name="annual_commission" data-vv-as="Comisión por Anualidad"/>
                        <span class="text-danger text-sm"  v-show="errors.has('annual_commission')">{{ errors.first('annual_commission') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Impuesto Nacional (ITBMS)</label>
                        <vs-input-number v-validate="'max_value:100|max:3'" v-model="app_config.national_tax.value" :step="1" name="national_tax" data-vv-as="Impuesto Nacional (ITBMS)"/>
                        <span class="text-danger text-sm"  v-show="errors.has('national_tax')">{{ errors.first('national_tax') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Límite de <strong>días</strong> para realizar solicitudes de adelanto</label>
                        <vs-input-number v-validate="'numeric|max_value:31|max:2'" v-model="app_config.gop_requests_limit_days.value" :step="1" name="gop_requests_limit_days" data-vv-as="Límite de días"/>
                        <span class="text-danger text-sm"  v-show="errors.has('gop_requests_limit_days')">{{ errors.first('gop_requests_limit_days') }}</span>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full">
                    <div class=" mb-6">
                        <label class="vs-input--label">Presets Recarga App Mobile</label>
                        <div class="flex flex-wrap">
                          <div v-for="(item, index) in app_config.recharge_preset_options.value" :key="index" class="w-1/4 px-2">
                            <vs-input class="w-full is-label-placeholder" v-model="app_config.recharge_preset_options.value[index]" v-validate="'numeric|duplicatedValue:recharge_preset_options|minToMaxOrder:recharge_preset_options|min_value:1|max_value:10000000|required'" name="recharge_presets" data-vv-as="Presets Recarga App Mobile" icon-pack="feather" icon="icon-dollar-sign" :label-placeholder="'Opción ' + (index + 1)"/>
                          </div>
                        </div>
                        <span class="text-danger text-sm" v-show="errors.has('recharge_presets')">{{ errors.first('recharge_presets') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Presets Transferencia App Mobile</label>
                        <div class="flex flex-wrap">
                          <div v-for="(item, index) in app_config.transfer_preset_options.value" :key="index" class="w-1/4 px-2">
                            <vs-input class="w-full is-label-placeholder" v-model="app_config.transfer_preset_options.value[index]" v-validate="'numeric|duplicatedValue:transfer_preset_options|minToMaxOrder:transfer_preset_options|min_value:1|max_value:10000000|required'" name="transfer_presets" data-vv-as="Presets Transferencia App Mobile" icon-pack="feather" icon="icon-dollar-sign" :label-placeholder="'Opción ' + (index + 1)"/>
                          </div>
                        </div>
                        <span class="text-danger text-sm" v-show="errors.has('transfer_presets')">{{ errors.first('transfer_presets') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">URL Términos y Condiciones</label>
                        <vs-input class="w-full" v-model="app_config.terms_url.value" name="terms_url" v-validate="'max:100'" data-vv-as="URL Términos y Condiciones"/>
                        <span class="text-danger text-sm"  v-show="errors.has('terms_url')">{{ errors.first('terms_url') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">URL Políticas de Privacidad</label>
                        <vs-input class="w-full" v-model="app_config.privacy_url.value" name="privacy_url" v-validate="'max:100'" data-vv-as="URL Políticas de Privacidad"/>
                        <span class="text-danger text-sm"  v-show="errors.has('privacy_url')">{{ errors.first('privacy_url') }}</span>
                    </div>
                    <div class=" mb-6">
                        <label class="vs-input--label">Texto Suspensión de Cuenta Acudiente</label>
                        <vs-textarea class="w-full" v-model="app_config.account_suspension_text.value" name="account_suspension_text" v-validate="'max:100'" data-vv-as="Texto Suspensión de Cuenta Acudiente"/>
                        <span class="text-danger text-sm"  v-show="errors.has('account_suspension_text')">{{ errors.first('account_suspension_text') }}</span>
                    </div>
                    <!-- <div class=" mb-6">
                        <label class="vs-input--label">WhatsApp Soporte Tiptap</label>
                        <vs-input class="w-full" v-model="app_config.whatsapp_number.value" name="whatsapp_number" v-validate="'max:100'"/>
                        <span class="text-danger text-sm"  v-show="errors.has('whatsapp_number')">{{ errors.first('whatsapp_number') }}</span>
                    </div> -->
                    <div class="mb-6">
                      <label class="vs-input--label">Texto aviso recarga ACH - Demora</label>
                      <vs-textarea class="w-full" name="ach_recharge_text" v-validate="'max:250'" v-model="app_config.ach_recharge_text.value" data-vv-as="de aviso de recarga ACH - Demora" />
                      <span class="text-danger text-sm"  v-show="errors.has('ach_recharge_text')">{{ errors.first('ach_recharge_text') }}</span>
                    </div>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-2" type="border" color="warning" @click="reset_data">Cancelar</vs-button>
                      <vs-button class="ml-4 mt-2" @click="submit_changes" id="saveButton" :disabled="!validateForm">Guardar</vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>

import moduleConfig from '@/store/config/moduleConfig.js';
import { Validator } from 'vee-validate';

export default {
  data() {
    return {
      app_config: {
        account_suspension_text: {id: 0, value: 0},
        privacy_url: {id: 0, value: 0},
        amount_min_recharge: {id: 0, value: 0},
        amount_min_trasnfer: {id: 0, value: 0},
        courtesy_days: {id: 0, value: 0},
        max_students: {id: 0, value: 0},
        price_per_student: {id: 0, value: 0},
        terms_url: {id: 0, value: 0},
        sales_commission: {id: 0, value: 0},
        annual_commission: {id: 0, value: 0},
        national_tax: {id: 0, value: 0},
        gop_requests_limit_days: {id: 0, value: 0},
        // whatsapp_number: {id: 0, value: 0},
        recharge_preset_options: {id: 0, value: 0},
        transfer_preset_options: {id: 0, value: 0},
        ach_recharge_text: {id: 0, value: ''}
      },
      activeTab: 0,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    }
  },
  methods: {
    submit_changes() {
      if(this.$validator.validateAll()) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: `Confirmar`,
          text: `Los parámetros afectan a toda la plataforma. ¿Seguro desea guardar los cambios?`,
          accept: this.save_changes,
          acceptText: "Confirmar"
        })
      }
    },
    save_changes() {
      // Loading
      this.$vs.loading()

      this.$validator.validateAll().then(result => {
        if (result) {
          let conf = []
          for (const property of Object.keys(this.app_config)) {
            conf.push({'id': this.app_config[property].id, 'value': this.app_config[property].value})
          }
          this.$store.dispatch(`moduleConfig/setConfig`, conf)
            .then(response => {
              if(response.status) {
                this.$vs.notify({
                  time: 8000,
                  title: 'Éxito',
                  text: 'Se han guardado los cambios.',
                  position: 'top-center',
                  iconPack: 'feather',
                  icon: 'icon-check-square',
                  color: 'success'
                })
              }
              this.$vs.loading.close()
            })
            .catch(error => {
              this.$vs.loading.close()
              this.$vs.notify({
                  time: 8000,
                  title: 'Error',
                  text: error,
                  position: 'top-center',
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
            })
        } else {
          this.$vs.loading.close()
        }

      }).catch(error => {
        console.log(error)
        this.$vs.loading.close()
      })
    },
    reset_data() {
      this.$router.go();
    },
    isArraySorted(a, n) {
        if (n == 1 || n == 0) {
          return true;
        }

        return parseInt(a[n - 1]) >= parseInt(a[n - 2]) && this.isArraySorted(a, n - 1);
    }
  },
  created() {
    // Register Config Module
    if(!moduleConfig.isRegistered) {
        this.$store.registerModule('moduleConfig', moduleConfig)
        moduleConfig.isRegistered = true
    }

    this.$store.dispatch(`moduleConfig/getConfig`)
    .then(() => {
        // this.config_data = this.$store.getters['moduleConfig/getConfig'];
        let config = JSON.parse(JSON.stringify(this.$store.getters['moduleConfig/getConfig']));

        config.forEach(element => {
          this.app_config[element.name] = {"id": element.id, "value": element.value}
        });
    })
    .catch(error => console.log(error));

    Validator.extend('duplicatedValue', {
      getMessage: field => 'El campo ' + field + ' no debe contener valores repetidos.',
      validate: (value, params) => (this.app_config[params[0]].value.filter(p => p == value).length <= 1)
    });
    new Validator({ duplicatedValueField: 'duplicatedValue' });

    Validator.extend('minToMaxOrder', {
      getMessage: field => 'El campo ' + field + ' debe estar ordenado de menor a mayor.',
      validate: (value, params) => (this.isArraySorted(this.app_config[params[0]].value, this.app_config[params[0]].value.length))
    });
    new Validator({ minToMaxOrderField: 'minToMaxOrder' });
  }
}

</script>
<style>
  .vuesax-app-is-ltr .vs-input--input.hasValue+.vs-placeholder-label, .vuesax-app-is-ltr .vs-input--input:focus+.vs-placeholder-label {
    transform: translate(-4px,-75%)!important;
  }
  .vs-input--input.hasIcon:not(.icon-after-input) + .vs-input--placeholder {
    padding-bottom: 0.2rem !important;
  }
</style>

