import axios from '@/axios.js';

export default {
    getConfig({commit}) {
        return new Promise((resolve,reject) => {
            axios.get(`pos/config`)
                .then(response => {
                    if(!response.data.status) 
                        reject(response.data.msg)

                    commit('SET_CONFIG', response.data.data);
                    resolve(response.data.data);
                })
                .catch(error => console.log(error));
        });
    },
    setConfig({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`pos/config/all`, {values: payload})
                .then(response => {
                    if(!response.data.status) 
                        return reject(response.data.msg)

                    commit('SET_CONFIG', response.data.data);
                    return resolve(response.data);
                })
                .catch(error => console.log(error));
        });
    }
}