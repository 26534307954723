import actions from './moduleConfigActions';
import getters from './moduleConfigGetters';
import mutations from './moduleConfigMutations';
import state from './moduleConfigState';

export default {
    isRegistered: false,
    namespaced: true,
    actions: actions,
    getters: getters,
    mutations: mutations,
    state: state
}